.InfoCard {
  .icn {
    opacity: 0.5;
    path {
      stroke: #fff;
    }
    circle {
      stroke: #fff;
    }
  }

  &--light {
    .icn {
      opacity: 1;

      path {
        stroke: #315d4f;
      }

      circle {
        stroke: #315d4f;
      }
    }
  }
}
