@font-face {
  font-family: 'DM Sans';
  src: local('DM Sans'), url(../public/dm_sans/DMSans-Regular.ttf) format('truetype'),
    url(../public/dm_sans/DMSans-Bold.ttf) format('truetype'), url(../public/dm_sans/DMSans-Italic.ttf) format('truetype');
}

.chakra-tabs__tab {
  position: relative;
}

.chakra-tabs__tab-indicator {
  transition-duration: 0ms !important;
}

.chakra-tabs-corner {
  width: 30px;
  height: 60px;
  position: absolute;
  bottom: 0;
  background: #f5f5f5;
  z-index: -3;

  &::after {
    content: '';
    background-color: #fff;
    height: 30px;
    width: 25px;
    display: block;
    position: absolute;
    bottom: -10px;
    z-index: -2;
  }

  &::before {
    content: '';
    background-color: #f5f5f5;
    height: 40px;
    width: 36px;
    display: block;
    position: absolute;
    bottom: 0;
    right: -18px;
    border-bottom-left-radius: 50%;
    z-index: -1;
  }

  &--left {
    left: -18px;
    transform: scaleX(-1);
  }

  &--right {
    right: -18px;
  }
}

.x-border {
  position: relative;

  &::after {
    content: '';
    display: block;
    clear: both;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    border-bottom: 2px dotted #d4d4d4;
    margin-top: -2px;
  }

  &--bottom {
    &::after {
      top: auto;
      bottom: 0;
    }
  }
}

.icn {
  &--light {
    color: #fff;
  }
}

.tos-privacy {
  h1 {
    font-size: 1.25em;
    font-weight: 600;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 1em;
    font-weight: 600;
    margin-top: 0.75em;
    margin-bottom: 0.25em;
  }

  ul {
    list-style-type: disc;
    margin-top: 0.5em;
    margin-bottom: 1em;
    margin-left: 1.25em;
  }

  p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}
.custom-quill .ql-container {
  height: 400px;
}

.unread {
  background-color: #315d4f !important;
  // margin: 7px 0px;
  padding: 16px;
  border-radius: 10px;
}

.text {
  margin: 5px !important;
  color: #FFF !important;
}