.SideBar {
  background: #315d4f;
  border-radius: 0 50px 50px 0;
  // position: fixed;
  // z-index: 1;

  &.collapsed {
    .SideBarLink {
      display: flex;
      align-items: center;
      width: 60px;
      justify-content: center;
      $padding-x: 0;
      padding-left: $padding-x;
      padding-right: $padding-x;

      svg {
        margin-right: 0;
      }
    }
  }

  .SideBarLink {
    color: #fff;
    border-radius: 30px;
    width: auto;
    padding: 12px 20px;
    display: flex;
    font-weight: normal;
    opacity: 0.5;

    svg {
      margin-right: 16px;
      stroke: #fff;
    }

    &.active {
      background: #fff;
      color: #315d4f;
      font-weight: 600;
      opacity: 1;

      svg {
        stroke: #315d4f !important;
      }
    }
  }

  .logo {
    padding: 5px 0 0 0;
    display: block;
    text-align: center;
  }
}
